import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { arrayRemove, doc, getFirestore, updateDoc } from "firebase/firestore";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  StorageReference,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";

type Props = {
  date: string;
  imageRef: StorageReference;
};

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "90%",
    margin: "10px auto",
  },
  image: {
    zIndex: 0,
    position: "relative",
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "500ms ease all",
      zIndex: 10,
    },
  },
  imageFullScreen: {
    pointerEvents: 'none',
    height: "90vh",
    margin: "auto",
    position: "fixed",
    top: "5vh",
    left: "50vw",
    width: "auto",
    transform: "translateX(-50%) !important",
    zIndex: 20,
    transition: "none !important",
  },
  deleteButton: {
    zIndex: 1,
    display: "flex",
    position: "absolute",
    width: 20,
    height: 20,
    borderRadius: 30,
    background: "#d95252",
    alignItems: "center",
    justifyContent: "center",
    top: -5,
    right: -5,
  },
  icon: {
    color: "white",
    fontSize: "14px !important",
  },
  backdrop: {
    zIndex: 15,
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.8)'
  }
}));

const ImageDisplay = (props: Props) => {
  const { imageRef, date } = props;
  const classes = useStyles();
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const deleteImage = useCallback(async () => {
    await deleteObject(imageRef);
    const db = getFirestore();
    await updateDoc(doc(db, "dates", date), {
      stamp: new Date(),
    });
  }, [date, imageRef]);

  const onDoubleClick = useCallback(async () => {
    setFullScreen(true);
  }, []);

  useEffect(() => {
    if (imageRef) getDownloadURL(imageRef).then((url) => setImageUrl(url));
  }, [imageRef]);

  return (
    <div className={classes.root}>
      <div className={cn([{[classes.backdrop] : fullScreen}])} onClick={()=>setFullScreen(false)}/>
      <img
        className={cn([
          classes.image,
          { [classes.imageFullScreen]: fullScreen },
        ])}
        src={imageUrl ?? ""}
        onDoubleClick={onDoubleClick}
      />
      <div className={classes.deleteButton}>
        <IconButton onClick={deleteImage}>
          <DeleteIcon classes={{ root: classes.icon }} />
        </IconButton>
      </div>
    </div>
  );
};

export default ImageDisplay;
