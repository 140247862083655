import moment, { Moment } from "moment";

type Props = {
  date: string;
};
const ColumnHeader = (props: Props) => {
  return (
    <div>
      <p>{moment(props.date).format("ddd D")}</p>
    </div>
  );
};

export { ColumnHeader };
