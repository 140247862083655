import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import ImageUploader from "./ImageUploader";


type Props = {
  open: boolean;
  onClose: () => any;
};

const UploadDialog = (props: Props) => {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload multiple images</DialogTitle>
      <DialogContent>
        <ImageUploader />
      </DialogContent>
    </Dialog>
  );
};

export default UploadDialog;
