import { makeStyles } from "@mui/styles";
import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    background: "#050505",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    background: "white",
    border: "none",
  },
}));

const LoginScreen = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      const data = new FormData(event.currentTarget);
      // eslint-disable-next-line no-console
      const auth = getAuth();
      try {
        await setPersistence(auth, browserLocalPersistence);
        await signInWithEmailAndPassword(
          auth,
          data.get("email") as unknown as string,
          data.get("password") as unknown as string
        );
      } catch (e) {}
      setLoading(false);
    },
    []
  );

  return (
    <div className={classes.container}>
      <div style={{ flexGrow: 1, width: "100%" }} />
      <Typography variant="h2">MAJO DIARY</Typography>
      <Typography variant="h5">Designed by Ma for Jo</Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1 }}
        style={{ flexGrow: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          variant="filled"
          autoFocus
          classes={{ root: classes.field }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          variant="filled"
          autoFocus
          classes={{ root: classes.field }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {loading ? (
            <CircularProgress style={{ color: "white" }} />
          ) : (
            "Sign In"
          )}
        </Button>
      </Box>

      {/*<div style={{ flexGrow: 1, width: "100%" }} />*/}
      <p style={{margin: "10px auto"}}>Happy Christmas 2021 Ma ❤️ Jo</p>
    </div>
  );
};

export { LoginScreen };
