import React from "react";
import moment from "moment";
import "moment/locale/de";
import "./App.css";
import { Container } from "./components/Container";
import useCurrentUser from "./utils/useCurrentUser";
import {LoginScreen} from "./components/Login";

moment.locale("de");

function App() {
  const user = useCurrentUser();

  return (
    <div className="App">{(!user || user?.isAnonymous) ? <LoginScreen /> : <Container />}</div>
  );
}

export default App;
