import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { GiphyFetch } from "@giphy/js-fetch-api";
import {
  arrayRemove,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const gf = new GiphyFetch("8gt8BQbtF4SYhzy2xUl2Sq4YN9drXLMl");

type Props = {
  date: string;
  gifId: string;
};

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "90%",
    margin: "10px auto",
  },
  image: {
    zIndex: 0,
    position: "relative",
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "500ms ease all",
      zIndex: 10,
    },
  },
  imageFullScreen: {
    pointerEvents: "none",
    height: "90vh",
    margin: "auto",
    position: "fixed",
    top: "5vh",
    left: "50vw",
    width: "auto",
    transform: "translateX(-50%) !important",
    zIndex: 20,
    transition: "none !important",
  },
  deleteButton: {
    zIndex: 1,
    display: "flex",
    position: "absolute",
    width: 20,
    height: 20,
    borderRadius: 30,
    background: "#d95252",
    alignItems: "center",
    justifyContent: "center",
    top: -5,
    right: -5,
  },
  icon: {
    color: "white",
    fontSize: "14px !important",
  },
  backdrop: {
    zIndex: 15,
    position: "fixed",
    height: "100vh",
    width: "100vw",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.8)",
  },
}));

const GifDisplay = (props: Props) => {
  const { gifId, date } = props;
  const classes = useStyles();
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [gif, setGif] = useState<any>(null);

  const deleteImage = useCallback(async () => {
    const db = getFirestore();
    await setDoc(
      doc(db, "dates", date),
      {
        gifs: arrayRemove(gifId),
        stamp: new Date(),
      },
      { merge: true }
    );
  }, [date, gifId]);

  const onDoubleClick = useCallback(async () => {
    setFullScreen(true);
  }, []);

  useEffect(() => {
    if (gifId) gf.gif(gifId).then((gif) => setGif(gif));
  }, [gifId]);

  console.log(gif);

  return (
    <div className={classes.root}>
      <div
        className={cn([{ [classes.backdrop]: fullScreen }])}
        onClick={() => setFullScreen(false)}
      />
      <img
        className={cn([
          classes.image,
          { [classes.imageFullScreen]: fullScreen },
        ])}
        src={gif?.data?.images.downsized.url}
        onDoubleClick={onDoubleClick}
      />
      <div className={classes.deleteButton}>
        <IconButton onClick={deleteImage}>
          <DeleteIcon classes={{ root: classes.icon }} />
        </IconButton>
      </div>
    </div>
  );
};

export default GifDisplay;
