import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from "@mui/styles";
import moment, { Moment } from "moment";
import UploadDialog from "./UploadDialog";
import {useState} from "react";

type Props = {
  start: Moment;
  end: Moment;
  onBackward: () => any;
  onForward: () => any;
  onToday: () => any;
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "black !important",
  },
}));

const Topbar = (props: Props) => {
  const classes = useStyles();
  const {start} = props;
  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  return (
    <AppBar position="static" classes={{ root: classes.root }}>
      <Toolbar>
        <IconButton onClick={props.onBackward}>
          <ArrowBackIosIcon style={{fill: "white"}} />
        </IconButton>
        <IconButton onClick={props.onToday} >
          <FiberManualRecordIcon style={{fill: "white"}}/>
        </IconButton>
        <IconButton onClick={props.onForward} >
          <ArrowForwardIosIcon style={{fill: "white"}}/>
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{textAlign: 'left'}}>
          MAJO DIARY | {start.format("MMM")}
        </Typography>
        <IconButton onClick={() => setOpenUploadDialog(true)} >
          <CloudUploadIcon style={{fill: "white"}}/>
        </IconButton>

      </Toolbar>
      <UploadDialog open={openUploadDialog} onClose={() => setOpenUploadDialog(false)}/>
    </AppBar>
  );
};

export { Topbar };
