import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Column } from "./components/Column";
import { Topbar } from "./components/Topbar";

const useStyles = makeStyles(() => ({
  container: {
    width: "100vw",
    flexDirection: "row",
    display: "flex",
  },
}));

const Container = () => {
  const classes = useStyles();
  const [viewDays, setViewDays] = useState(window.innerWidth > 800 ? 7 : 1);
  const [startDate, setStartDate] = useState(window.innerWidth > 800 ? moment().utc().startOf("week") : moment().utc());
  const [endDate, setEndDate] = useState(moment().utc().add(viewDays, "days"));
  const arrayIndex: number[] = Array.from(Array(viewDays).keys());

  const reportWindowSize = useCallback(() => {
    const nDays = window.innerWidth > 800 ? 7 : 1
    setViewDays(nDays);
    setEndDate(moment(startDate).add(nDays, "days"))
  }, [startDate]);

  useEffect(() => {
    window.addEventListener("resize", reportWindowSize);
    return () => {
      window.removeEventListener("resize", reportWindowSize);
    }
  });

  const onBackwards = useCallback(() => {
    setStartDate(moment(startDate).subtract(viewDays, "days"));
    setEndDate(moment(endDate).subtract(viewDays, "days"));
  }, [endDate, startDate, viewDays]);

  const onForwards = useCallback(() => {
    setStartDate(moment(startDate).add(viewDays, "days"));
    setEndDate(moment(endDate).add(viewDays, "days"));
  }, [endDate, startDate, viewDays]);

  const onToday = useCallback(() => {
    if (viewDays > 1) {
      setStartDate(moment().utc().startOf("week"));
      setEndDate(moment().utc().startOf("week").add(viewDays, "days"));
    }
    else {
      setStartDate(moment().utc().startOf("day"));
      setEndDate(moment().utc().startOf("day").add(1, "days"));
    }
  }, [viewDays]);

  return (
    <div>
      <Topbar
        start={startDate}
        end={endDate}
        onBackward={onBackwards}
        onForward={onForwards}
        onToday={onToday}
      />
      <div className={classes.container}>
        {arrayIndex.map((i) => (
          <Column date={moment(startDate).add(i, "days").format("YYYYMMDD")} nDays={viewDays} />
        ))}
      </div>
    </div>
  );
};

export { Container };
