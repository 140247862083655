import React, { useMemo, useState } from "react";
import { Grid } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { makeStyles } from "@mui/styles";
import { Button, Dialog, TextField } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "80%",
    margin: "10px auto",
    fontSize: 10,
    color: "gray",
  },
  textInput: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  button: {
    color: "#7f6bd9",
    background: 'transparent',
    outline: 'none',
    border: 'none'
  },
}));

type Props = {
  onGifSelected: (gifId: string) => any;
};

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const gf = new GiphyFetch("8gt8BQbtF4SYhzy2xUl2Sq4YN9drXLMl");

const GifPicker = (props: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  // configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
  const fetchGifs = useMemo(() => {
    if (searchText?.length) {
      console.log(searchText);
      return (offset: number) => gf.search(searchText, { offset, limit: 10 });
    }
    return (offset: number) => gf.trending({ offset, limit: 10 });
  }, [searchText]);

  const onGifClick = (gif: any, event: any) => {
    event.preventDefault();
    props.onGifSelected(gif.id);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <button onClick={() => setOpen(true)} className={classes.button }>
        Pick GIF
      </button>
      <Dialog
        open={open}
        onBackdropClick={() => setOpen(false)}
        onClose={() => setOpen(false)}
      >
        <TextField
          placeholder="Search for something..."
          value={searchText}
          onChange={(v) => setSearchText(v.target.value)}
        />
        {open && (
          <Grid
            key={searchText}
            width={window.innerWidth > 800 ? 500 : 300}
            columns={window.innerWidth > 800 ? 3 : 1}
            fetchGifs={fetchGifs}
            onGifClick={onGifClick}
          />
        )}
      </Dialog>
    </div>
  );
};

export default GifPicker;
