import { makeStyles } from "@mui/styles";
import moment from "moment";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  doc,
  setDoc,
  getFirestore,
  onSnapshot,
  arrayUnion,
} from "firebase/firestore";
import { ColumnHeader } from "./ColumnHeader";
import { useCallback, useEffect, useState } from "react";
import ImageUploader from "./ImageUploader";
import ImageDisplay from "./ImageDisplay";
import { getStorage, ref, list, StorageReference } from "firebase/storage";
import GifPicker from "./GifPicker";
import GifDisplay from "./GifDisplay";

type Props = {
  date: string;
  nDays: number;
};

const useStyles = makeStyles(() => ({
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: "1px solid #e7e9eb",
    height: "calc(100vh - 70px)",
    "& .uploader": {
      // opacity: 0.5,
      width: "100%",
    },
    "&:hover .uploader": {
      width: "100%",
      display: "initial",
    },
  },
  editorRoot: {
    width: "90%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
    boxSizing: "border-box",
    background: "#fafafa",
    boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
    borderRadius: 8,
    textAlign: "left",
    "& .ProseMirror": {
      outline: "none",
    },
  },
}));

const placeholders = [
  "What happened?",
  "Anything special?",
  "Something to remember?",
  "What is a good day?",
  "Abenteuer?",
  "Good start of the weekend?",
  "Happy Sunday?",
];

const Column = (props: Props) => {
  const { date, nDays } = props;
  const [imageRefs, setImageRefs] = useState<StorageReference[]>([]);
  const [gifList, setGifList] = useState<any[]>([]);
  const classes = useStyles();

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        // Use a placeholder:
        placeholder: placeholders[moment(date).weekday()],
      }),
    ],
    content: null,
  });

  const loadImages = useCallback(async () => {
    const storage = getStorage();
    const listRef = ref(storage, `images/${date}`);

    // Fetch the first page of 100.
    const page = await list(listRef, { maxResults: 100 });
    setImageRefs(page.items);
  }, [date]);

  useEffect(() => {
    const db = getFirestore();
    return onSnapshot(doc(db, "dates", props.date), async (doc) => {
      loadImages();
      const data: any = doc.data();
      if (data?.text) {
        console.log("Setting ", data.text.content, " for ", props.date);
        editor?.commands?.setContent(data.text?.content);
      } else {
        editor?.commands?.setContent(null);
        // @ts-ignore
        if (
          editor?.extensionManager.extensions.find(
            ({ name }) => name === "placeholder"
          )
        ) {
          editor.extensionManager.extensions.find(
            ({ name }) => name === "placeholder"
          )!.options.placeholder = placeholders[moment(props.date).weekday()];
        }
      }
      setGifList(data?.gifs ?? null);
    });
  }, [editor, loadImages, props.date]);

  const onBlur = useCallback(async () => {
    const db = getFirestore();
    await setDoc(
      doc(db, "dates", props.date),
      {
        text: editor?.getJSON(),
      },
      { merge: true }
    );
  }, [editor, props.date]);

  const onGifSelected = useCallback(
    async (gifId: string) => {
      const db = getFirestore();
      await setDoc(
        doc(db, "dates", props.date),
        {
          gifs: arrayUnion(gifId),
        },
        { merge: true }
      );
    },
    [props.date]
  );

  return (
    <div className={classes.column} style={{ width: `${100 / nDays}%` }}>
      <ColumnHeader date={date} />
      <EditorContent
        className={classes.editorRoot}
        editor={editor}
        onBlur={onBlur}
      />
      {imageRefs.map((ref) => (
        <ImageDisplay key={ref.fullPath} imageRef={ref} date={date} />
      ))}
      {gifList?.map((gifId) => (
        <GifDisplay gifId={gifId} date={date} />
      ))}
      <div style={{ width: "100%", flexGrow: 1 }} />
      {/*<FactOfTheDay date={date} />*/}
      <div className="uploader">
        <GifPicker onGifSelected={onGifSelected} />
        <ImageUploader date={date} />
      </div>
    </div>
  );
};

export { Column };
