import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useEffect, useState } from "react";

const useCurrentUser = () => {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const auth = getAuth();
  useEffect(() => {
    const unlisten = onAuthStateChanged(auth, (user) => {
      return user ? setAuthUser(user) : setAuthUser(null);
    });
    return () => {
      unlisten();
    };
  }, [auth]);

  return authUser;
};

export default useCurrentUser;
