import React, {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useState,
} from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Dropzone from "react-dropzone";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { nanoid } from "nanoid";
import {
  doc,
  getFirestore,
  updateDoc,
  arrayUnion,
  increment,
  setDoc,
} from "firebase/firestore";
import moment from "moment";
import heic2any from "heic2any";
import { LinearProgress, Box, Typography } from "@mui/material";
import { findEXIFinHEIC, findEXIFinJPEG } from "../../utils/exif";
import { makeStyles } from "@mui/styles";

type Props = {
  date?: string;
};

function LinearProgressWithLabel(props: any) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  dropArea: ({ loading }: any) => ({
    width: "80%",
    boxSizing: "border-box",
    minHeight: 60,
    padding: 10,
    margin: "10px auto",
    color: "#7f6bd9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      background: "#f3f9ff",
      border: loading ? "none" : "2px dashed #7f6bd9",
    },
  }),
}));

async function uploadFile(
  fileId: string,
  date: string,
  file: File | Blob,
  exif: any
) {
  // Create a root reference
  const storage = getStorage();

  // Create a reference to 'images/mountains.jpg'
  console.log(`images/${date}/${fileId}`);
  const fileRef = ref(storage, `/images/${date}/${fileId}`);

  const result = await uploadBytes(fileRef, file);
  console.log(result);

  // const downloadUrl = await getDownloadURL(fileRef);

  // Also store exif, maybe it will come handy in the future
  // await setDoc(doc(db, "exifs", fileId.split(".")[0]), exif, { merge: true });

  // return downloadUrl;

  const db = getFirestore();

  // Update day document with the new file
  await setDoc(
    doc(db, "dates", date),
    {
      stamp: new Date(),
    },
    { merge: true }
  );
}

async function extractExif(file: File, ext: string) {
  console.log(`Extracting EXIF from ${ext} file`);
  try {
    const exif: any =
      file.type.toLowerCase() === "image/heic" ||
      file.name.toLowerCase().includes(".heic")
        ? findEXIFinHEIC(await new Response(file).arrayBuffer())
        : findEXIFinJPEG(await new Response(file).arrayBuffer());
    return exif ?? {};
  } catch (e) {
    console.log(e);
    return {};
  }
}

async function heic2JPEG(file: File) {
  if (
    file.type.toLowerCase() === "image/heic" ||
    file.name.toLowerCase().includes(".heic")
  ) {
    console.log("Converting HEIC -> JPEG");
    let jpegImage = await heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.85,
    });
    if (Array.isArray(jpegImage)) return jpegImage[0];
    return jpegImage;
  }
  return file;
}

async function processImage(file: File, ext: string, date?: string) {
  // If no date is provided, infer from EXIF
  // @ts-ignore
  const exif: any = await extractExif(file, ext);
  if (!date) {
    console.log("No date provided for ", file.name);
    const dateStr = exif?.["DateTime"] ?? exif?.["DateTimeOriginal"];
    console.log(`Got date: `, dateStr);
    let dateMoment = moment(dateStr, "YYYY:MM:DD HH:mm:SS");
    console.log(`Converted `, dateMoment);
    if (!dateMoment.isValid()) dateMoment = moment();
    date = dateMoment.format("YYYYMMDD");
    console.log(`Final date `, date);
  }
  if (ext === "heic") ext = "jpeg"
  const fileId = `${nanoid()}.${ext}`;
  await uploadFile(fileId, date, await heic2JPEG(file), exif);
}

const ImageUploader = (props: Props) => {
  const { date } = props;
  const [uploadState, setUploadState] = useState(0);
  const classes = useStyles({ loading: uploadState > 0 });

  const onImageChange = useCallback(
    async (files) => {
      if (files?.length) {
        setUploadState(10);
        for (let i = 0; i < files?.length; i++) {
          try {
            const file = files[i];
            console.log(`Start processing ${file.name}`);
            const ext = file.name.split(".").pop() ?? "jpeg";
            await processImage(file, ext, date);
          } catch (e) {
            console.error("Failed loading", files[i].name);
          }
          setUploadState(((i + 1) / files?.length) * 100);
        }
        setTimeout(() => {
          setUploadState(0);
        }, 500);
      }
    },
    [date]
  );

  return (
    <div style={{ width: "100%" }}>
      <Dropzone
        onDropAccepted={onImageChange}
        accept="image/jpeg, image/heic, image/png, image/gif"
        disabled={uploadState > 0}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className={classes.dropArea}>
              <input {...getInputProps()} />
              {uploadState > 0 ? (
                <LinearProgressWithLabel
                  variant="determinate"
                  value={uploadState}
                  style={{ width: "100%" }}
                />
              ) : (
                <CloudUploadIcon />
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default ImageUploader;
